/** 文件上传中心-导出状态枚举 */
export const EXPORT_STATUS_ENUM = {
  ing: {
    value: 1,
    label: "处理中",
  },
  finish: {
    value: 2,
    label: "处理完成",
  },
};
