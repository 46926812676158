<template>
  <section class="page-wrap">
    <!-- 搜索模块 start -->
    <section class="form">
      <div class="form-item">
        <span class="label">文件名称：</span>
        <el-input
          placeholder="请输入文件名称"
          v-model="formData.file_name"
          clearable
        >
        </el-input>
      </div>
      <div class="form-item">
        <el-button
          slot="suffix"
          type="primary"
          size="medium"
          icon="el-icon-search"
          @click="onHandleSearch('all')"
          >查询</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
          title="重置并刷新"
        ></el-button>
      </div>
    </section>
    <!-- 搜索模块 end -->

    <!-- 内容区 start -->
    <section class="content-wrap">
      <table-list :dataList="dataList" v-loading="listLoading"></table-list>
    </section>
    <!-- 内容区 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        v-if="total"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { postFileUploadCenterList } from "@/api/general/origin.js";
import TableList from "./modules/table-list";

export default {
  name: "ExportCenter",
  components: {
    TableList,
  },
  data() {
    return {
      listLoading: true,
      // 表单查询项
      formData: {
        file_name: "",
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 表单参数拷贝，用于重置
      dataList: [], // 列表数据
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  destroyed() {
    // clearInterval(this.timer);
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.onHandleSearch();
    },
    /**
     * 查询数据
     */
    onHandleSearch(type) {
      this.dataList = [];
      /** 当有内容时，且手动点击了查询，则重置到第一页 */
      if (type === "all" && this.formData.file_name) {
        this.formData.page = 1;
      }
      this.ajaxGetExportCenterList();
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.ajaxGetExportCenterList();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.onHandleSearch();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.onHandleSearch();
    },
    /**
     * 查询列表数据
     */
    async ajaxGetExportCenterList() {
      this.listLoading = true;
      const params = {
        ...this.formData,
      };
      try {
        const res = await postFileUploadCenterList(params);
        this.total = res.data.total;
        this.dataList = res.data.data;
        console.log("🆒 list", res);
      } catch (err) {
        console.log("ajx postFileUploadCenterList err", err);
      } finally {
        this.listLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  height: calc(100% - 12px);
  padding: 6px;
  display: flex;
  flex-direction: column;
  .form {
    padding: 20px 10px;
    display: flex;
    justify-content: right;
    align-content: center;
    gap: 20px;
    background: #fff;
    &-item {
      display: flex;
      align-items: center;
      .label {
        width: 100px;
      }
      .el-input {
        flex: 1;
      }
      .refresh {
        background: #333333;
        border-color: #333333;
      }
    }
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .el-pagination {
    padding: 10px 10px;
  }
}
</style>
