<template>
  <el-table
    :data="dataList"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    height="100%"
    border
  >
    <el-table-column label="序号" type="index" width="90" align="center">
    </el-table-column>
    <el-table-column
      label="文件名称"
      prop="fileName"
      minWidth="200"
      show-overflow-tooltip
      align="center"
    >
    </el-table-column>
    <el-table-column label="模块" prop="modelTypeName" align="center">
    </el-table-column>
    <el-table-column label="原始文件地址" prop="originFileUrl" align="center">
      <template slot-scope="scope">
        <el-button type="text" @click="onHandelClick(scope.row, 1)"
          >点击下载</el-button
        >
      </template>
    </el-table-column>
    <el-table-column label="导入结果下载" prop="respFileUrl" align="center">
      <template slot-scope="scope">
        <el-button
          type="text"
          @click="onHandelClick(scope.row, 2)"
          v-if="scope.row.respFileUrl"
          >点击下载
        </el-button>
        <div v-else>-</div>
      </template>
    </el-table-column>
    <el-table-column
      label="执行成功率"
      minWidth="100"
      prop="successRate"
      align="center"
    >
      <template slot-scope="scope">
        <span v-if="scope.row.successRate">{{ scope.row.successRate }}%</span>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column
      label="导入状态"
      prop="status"
      minWidth="100"
      align="center"
    >
      <template slot-scope="scope">
        <span>{{
          Object.values(EXPORT_STATUS_ENUM).find(
            (item) => item.value === scope.row.status
          ).label
        }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="导入时间"
      prop="createTime"
      minWidth="110"
      align="center"
    >
    </el-table-column>
    <el-table-column
      label="操作人"
      prop="userName"
      minWidth="100"
      align="center"
    >
      <template slot-scope="scope">
        <span>{{ scope.row.userName || "-" }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { EXPORT_STATUS_ENUM } from "../utils/enum";

export default {
  name: "TableList",
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      EXPORT_STATUS_ENUM,
    };
  },
  methods: {
    /**
     * 下载
     */
    onHandelClick(row, type) {
      let tmpArr = row.fileName.split(".");
      console.log(tmpArr[0], "33");
      if (type == 1) {
        this.download(row.originFileUrl, tmpArr[0]);
      } else {
        this.download(row.respFileUrl, `${tmpArr[0]}-结果`);
      }
    },
    download(url1, fileName) {
      let self = this;
      const url = url1;
      let x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function () {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
  },
};
</script>

<style lang="scss" scoped></style>
